import React from "react"
import { Container } from "./Container"

export const PersonalInfo = () => {
  const linkClassName = "text-primary tracking-widest hover:text-primary-dark"
  return (
    <section className="my-auto">
      <Container>
        <div>
          <h1 className="text-4xl font-medium font-serif py-1 text-center">
            Hello World!
          </h1>
          <h3 className="py-1 text-xl text-center font-serif">
            Here is a little bit about me...
          </h3>
        </div>
        <div className="mb-10">
          <p className="leading-loose   text-justify py-2  md:px-20 lg:px-32">
            I am Elizabeth Vincent M, a software engineer. I was born in 1992
            and grew up in Kerala, India. In 2013, I graduated with a Bachelors
            degree in Electrical and Electronics Engineering, from{" "}
            <a
              href="https://www.mec.ac.in/"
              target="_blank"
              rel="noopener noreferrer"
              className={linkClassName}
            >
              Model Engineering College.
            </a>
            I joined{" "}
            <a
              href="https://www.bosch-india-software.com/en/our-company/about-us/"
              target="_blank"
              rel="noopener noreferrer"
              className={linkClassName}
            >
              BOSCH, India
            </a>{" "}
            through on-campus recruitment, and has been coding ever since.
            <br />I live in Kochi, Kerala, with my husband Justin and our little
            boy, Louie.
          </p>
        </div>
      </Container>
    </section>
  )
}
