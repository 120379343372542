import React from "react"
import SEO from "../components/seo"
import { Header } from "../components/Header"
import { PersonalInfo } from "../components/PersonalInfo"
import { Footer } from "../components/Footer"

const About = () => {
  return (
    <section className="min-h-screen flex flex-col justify-between">
      <SEO title="About" />
      <Header pageHeader="About Me" />
      <PersonalInfo />
      <Footer />
    </section>
  )
}

export default About
